import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import _defineProperty from "/gva_web/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { mapGetters } from 'vuex';
var path = process.env.VUE_APP_BASE_API;
export default {
  name: "customPic",
  props: {
    picType: {
      type: String,
      required: false,
      default: "avatar"
    },
    picSrc: {
      type: String,
      required: false,
      default: ""
    }
  },
  data: function data() {
    return {
      path: path
    };
  },
  computed: _objectSpread(_objectSpread({}, mapGetters('user', ['userInfo'])), {}, {
    avatar: function avatar() {
      if (this.picSrc === '') {
        if (this.userInfo.headerImg !== '' && this.userInfo.headerImg.slice(0, 4) === "http") {
          return this.userInfo.headerImg;
        }
        return this.path + this.userInfo.headerImg;
      } else {
        if (this.picSrc !== '' && this.picSrc.slice(0, 4) === "http") {
          return this.picSrc;
        }
        return this.path + this.picSrc;
      }
    },
    file: function file() {
      if (this.picSrc && this.picSrc.slice(0, 4) !== "http") {
        return this.path + this.picSrc;
      }
      return this.picSrc;
    }
  })
};