var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("span", {
    staticClass: "headerAvatar"
  }, [_vm.picType === "avatar" ? [_vm.userInfo.headerImg ? _c("el-avatar", {
    attrs: {
      size: 30,
      src: _vm.avatar
    }
  }) : _c("el-avatar", {
    attrs: {
      size: 30,
      src: require("@/assets/noBody.png")
    }
  })] : _vm._e(), _vm._v(" "), _vm.picType === "img" ? [_vm.userInfo.headerImg ? _c("img", {
    staticClass: "avatar",
    attrs: {
      src: _vm.avatar
    }
  }) : _c("img", {
    staticClass: "avatar",
    attrs: {
      src: require("@/assets/noBody.png")
    }
  })] : _vm._e(), _vm._v(" "), _vm.picType === "file" ? [_c("img", {
    staticClass: "file",
    attrs: {
      src: _vm.file
    }
  })] : _vm._e()], 2);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };